import * as formik from "formik";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import * as yup from "yup";
import logoBusinessVeritatrust from "../../assets/logo-veritatrust-business.png";
import CreateWebshopInput from "../../components/CreateWebshopInput";
import StepperItem from "../../components/StepperItem";
import TermsConditions from "../../components/TermsConditions";
import "../../styles/bootstrap.css";
import "../../styles/dashboard.css";
import "../../styles/flaticon_veritatrust.css";
import "../../styles/style.css";
import { useAxios } from "../../utils/hook/useAxios";

function CompleteProfilePage() {
  const axios = useAxios();

  const [stepperArray, setStepperArray] = useState([
    "active",
    "active",
    "",
    "",
  ]);
  const [stepperMsg, setStepperMsg] = useState("Complete your profile");
  const [stepperClass, setStepperClass] = useState("collecting");
  const [stepperItemComponent, setStepperItemComponent] = useState(1);
  const [countryCode, setCountryCode] = useState("+1");
  const [countryName, setCountryName] = useState("usa");
  const [user, setUser] = useState({});
  // Par exemple, le code du pays +1 pour les États-Unis
  const [emailDomainName, setEmailDomaineName] = useState(null);
  const [displayMessage, setDisplayMessage] = useState(false);
  const [webshop, setWebshop] = useState(null);
  const [merchantID, setmerchantID] = useState(null);
  const [merchantData, setmerchantData] = useState(null);

  const [modalShow, setModalShow] = useState(false);

  function handleModalClick() {
    setModalShow(true);
  }

  const handleWebshopSelection = (webshop) => {
    setWebshop(webshop.website);
    setmerchantID(webshop.id);
  };

  useEffect(() => {
    const fetchAuthStatus = async () => {
      try {
        const response = await axios.get("/");
        setUser(response.data);

        console.log(response);
        if (response.data.website) {
          if (response.data.status === "completed") {
            setStepperArray(["active", "active", "active", ""]);
            setStepperMsg("Profile completed");
            setStepperClass("verify");
            setStepperItemComponent(3);
          }
        } else {
          if (response.data.status === "completed") {
            setStepperArray(["active", "active", "", ""]);
            setStepperMsg("Complete your profile");
            setStepperClass("collecting");
            setStepperItemComponent(2);
          }
        }

        if (response.data.status === "domaine_verified") {
          setStepperArray(["active", "active", "active", ""]);
          setStepperMsg("Profile completed");
          setStepperClass("verify");
          setStepperItemComponent(3);
        }
        if (response.data.status === "validated") {
          setStepperArray(["active", "active", "active", "active"]);
          setStepperMsg("Profile completed");
          setStepperClass("display");
          setStepperItemComponent(4);
        }

        if (response.data.status === "verified") {
          setStepperArray(["active", "", "", ""]);
          setStepperMsg("Complete your profile");
          setStepperClass("");
          setStepperItemComponent(1);
        }
        const responseMerchand = await fetch(
          `https://api.veritatrust.com/v1/merchant_profiles/${response.data.website}`
        );

        if (!responseMerchand.ok) {
          throw new Error(`HTTP error! Status: ${responseMerchand.status}`);
        }
        const data = await responseMerchand.json();
        setmerchantData(data);
      } catch (error) {}
    };

    fetchAuthStatus();
  }, []);

  useEffect(() => {
    if (user.website) {
      if (user.status === "completed") {
        setStepperArray(["active", "active", "active", ""]);
        setStepperMsg("Profile completed");
        setStepperClass("verify");
        setStepperItemComponent(3);
      }
    } else {
      if (user.status === "completed") {
        setStepperArray(["active", "active", "", ""]);
        setStepperMsg("Complete your profile");
        setStepperClass("collecting");
        setStepperItemComponent(2);
      }
    }
    if (user.status === "domaine_verified") {
      setStepperArray(["active", "active", "active", ""]);
      setStepperMsg("Profile completed");
      setStepperClass("verify");
      setStepperItemComponent(3);
    }
    if (user.status === "validated") {
      setStepperArray(["active", "active", "active", "active"]);
      setStepperMsg("Profile completed");
      setStepperClass("display");
      setStepperItemComponent(4);
    }
    if (user.status === "verified") {
      setStepperArray(["active", "", "", ""]);
      setStepperMsg("Complete your profile");
      setStepperClass("");
      setStepperItemComponent(1);
    }
  }, [user]);

  const [darkMode, setDarkMode] = useState(false);

  const handleToggle = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle("dark-theme-variables");
  };

  const { Formik } = formik;

  const schema = yup.object().shape({
    corporate_name: yup.string().required(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    jobTitle: yup.string().required(),
    country: yup.string(),
    password: yup.string().notRequired(),
    email: yup.string().notRequired().email(),
    phoneNumber: yup.string().required(),
    terms: yup.bool().required().oneOf([true], "Terms must be accepted"),
  });

  const onSubmit = (data) => {
    console.log(data); // Display form data in the console
    const merchantuser = {
      first_name: data.first_name || user.first_name,
      last_name: data.last_name || user.last_name,
      email: user.email,
      corporate_name: data.corporate_name || user.corporate_name,
      website: webshop || user.website,
      merchant_id: merchantID || user.merchant_id,

      country: data.country || user.country,
      phoneNumber: user.phoneNumber
        ? user.phoneNumber
        : countryCode + data.phoneNumber || user.phoneNumber,
      jobTitle: data.jobTitle || user.jobTitle,
      status: "domaine_verified",
    };

    const fetchAuthStatus = async () => {
      try {
        const response = await axios.post("/update", merchantuser);
        console.log(response);

        if (response.status === 200) {
          setStepperArray(["active", "active", "active"]);
          setStepperMsg("Profile completed");
          setStepperClass("verify");
          setStepperItemComponent(3);
          window.location.href = "https://b2b.veritatrust.com/";
        }
      } catch (error) {}
    };

    fetchAuthStatus();
  };

  const handleChangeCountry = async (event) => {
    // Make the fetch request
    setCountryName(event.target.value);
    try {
      const response = await fetch(
        `https://api.veritatrust.com/v1/data/countries?q=${event.target.value}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setCountryCode(data[0].phone_code);
      console.log("country code: ", data[0].phone_code);
      console.log(data);
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  const handleEmailDomaineName = async (event) => {
    setEmailDomaineName(event.target.value);
  };

  const handleSendEmailDomaineName = async () => {
    const fetchAuthStatus = async () => {
      try {
        const response = await axios.post("/send-verify-domain", {
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: emailDomainName + "@" + user.website.replace("www.", ""),
        });
        console.log(response);

        if (response.status === 200) {
          setDisplayMessage(true);
        }
      } catch (error) {}
    };

    fetchAuthStatus();
  };
  const [loading, setLoading] = useState(false);
  const [displayError, setDisplayError] = useState(false);

  const [message, setMessage] = useState("");
  const [displayMSG, setDisplayMSG] = useState(0);

  const handleVerify = async () => {
    setDisplayMSG(displayMSG + 1);
    if (!merchantData?.id) {
      setMessage("Please enter a merchant ID.");
      return;
    }

    setLoading(true);
    setMessage("");

    try {
      // Appeler le backend pour vérifier le marchand
      const response = await fetch(
        `https://api.b2b.veritatrust.com/v1/merchant-domain/verify-merchant/${merchantData?.id}/${user.id}`
      );

      const data = await response.json();

      if (response.ok) {
        setMessage(data.message || "Merchant verified successfully!");
        window.location.href = "/";
      } else {
        setMessage(data.error || "Failed to verify merchant.");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessage("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <header className="bg-primary login d-flex mb-3  w-100">
        <img
          className="my-2 mx-auto"
          src={logoBusinessVeritatrust}
          alt="veritatrust business"
          width="180"
        />

        <a
          className="d-flex justify-center text-white me-4"
          href="https://api.b2b.veritatrust.com/v1/logout"
        >
          <span className="material-symbols-sharp">Logout</span>
          <h3>Logout</h3>
        </a>
        <div className="right">
          <div className="theme-toggler text-light me-5 mt-3">
            <span
              className={
                darkMode
                  ? "material-symbols-sharp"
                  : "material-symbols-sharp active"
              }
              onClick={handleToggle}
            >
              light_mode
            </span>
            <span
              className={
                darkMode
                  ? "material-symbols-sharp active"
                  : "material-symbols-sharp"
              }
              onClick={handleToggle}
            >
              dark_mode
            </span>
          </div>
        </div>
      </header>
      <StepperItem
        propsMessage={stepperMsg}
        propsClass={stepperClass}
        propsStepValue={stepperArray}
      />
      {
        <div className="container">
          <main className="">
            <div className="row align-items-center">
              <div className="col-lg-6 mx-auto">
                {console.log("stepperItemComponent", stepperItemComponent)}
                {stepperItemComponent === 2 && (
                  <div className="reviews login-panel p-5">
                    <h2 className="mb-3 text-center">
                      Complete your profile to access in dashboard
                    </h2>
                    <Formik
                      validationSchema={schema}
                      onSubmit={onSubmit}
                      initialValues={{
                        website: user.website || "",
                        corporate_name: user.corporate_name || "",
                        first_name: user.first_name || "",
                        last_name: user.last_name || "",
                        country: user.country || "",
                        terms: false,
                        email: user.email || "",
                        phoneNumber: user.phoneNumber || "",
                        jobTitle: user.jobTitle || "",
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        values,
                        touched,
                        errors,
                      }) => (
                        <Form noValidate onSubmit={handleSubmit} className="">
                          <CreateWebshopInput
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            handleSelectWebshop={handleWebshopSelection}
                          />

                          <Form.Group
                            controlId="validationFormikEnterprise"
                            className="mt-3"
                          >
                            {/* <Form.Label>Enterprise </Form.Label> */}
                            <FloatingLabel
                              controlId="floatingEnterpriseInput"
                              label="Business name"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                name="corporate_name"
                                value={values.corporate_name}
                                onChange={handleChange}
                                placeholder="Business name"
                                isValid={
                                  touched.corporate_name &&
                                  !errors.corporate_name
                                }
                              />
                            </FloatingLabel>
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            controlId="validationFormik03"
                            className="mt-3"
                          >
                            <FloatingLabel
                              controlId="floatingFisrtnameInput"
                              label="First name"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                name="first_name"
                                value={values.first_name}
                                onChange={handleChange}
                                placeholder="First name"
                                isValid={
                                  touched.first_name && !errors.first_name
                                }
                              />
                            </FloatingLabel>
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            controlId="validationFormik03"
                            className="mt-3"
                          >
                            <FloatingLabel
                              controlId="floatingLastnameInput"
                              label="Last name"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                name="last_name"
                                value={values.last_name}
                                onChange={handleChange}
                                placeholder="Last name"
                                isValid={touched.last_name && !errors.last_name}
                              />
                            </FloatingLabel>
                            <Form.Control.Feedback>
                              Looks good!
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            controlId="validationFormik03"
                            className="mt-3"
                          >
                            <FloatingLabel
                              controlId="floatingJobtitleInput"
                              label="Job title"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="job title"
                                name="jobTitle"
                                value={values.jobTitle}
                                onChange={handleChange}
                                isInvalid={!!errors.jobTitle}
                              />
                            </FloatingLabel>
                            <Form.Control.Feedback type="invalid">
                              {errors.jobTitle}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            controlId="validationFormik03"
                            className="mt-3"
                          >
                            <InputGroup hasValidation>
                              <FloatingLabel
                                controlId="floatingInput"
                                label={user.email}
                                className="mb-3"
                              >
                                <Form.Control
                                  type="text"
                                  placeholder={user.email}
                                  aria-describedby="inputGroupPrepend"
                                  name="email"
                                  value={user.email}
                                  onChange={handleChange}
                                  isInvalid={!!errors.email}
                                  disabled={true}
                                />
                              </FloatingLabel>
                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Form.Group>

                          <Row>
                            <Form.Group
                              controlId="validationFormik04"
                              as={Col}
                              md="5"
                              className="mt-3"
                            >
                              <FloatingLabel
                                controlId="floatingCountryInput"
                                label="Country"
                                className="mb-3"
                              >
                                <Form.Select
                                  name="state" // Set the name for Formik to track
                                  value={countryName}
                                  onChange={handleChangeCountry}
                                  isInvalid={!!errors.state}
                                >
                                  <option value="" disabled>
                                    Select a country
                                  </option>
                                  <option value="afghanistan">
                                    Afghanistan
                                  </option>
                                  <option value="south_africa">
                                    South Africa
                                  </option>
                                  <option value="albania">Albania</option>
                                  <option value="algeria">Algeria</option>
                                  <option value="germany">Germany</option>
                                  <option value="andorra">Andorra</option>
                                  <option value="angola">Angola</option>
                                  <option value="saudi_arabia">
                                    Saudi Arabia
                                  </option>
                                  <option value="argentina">Argentina</option>
                                  <option value="armenia">Armenia</option>
                                  <option value="australia">Australia</option>
                                  <option value="austria">Austria</option>
                                  <option value="azerbaijan">Azerbaijan</option>
                                  <option value="bahamas">Bahamas</option>
                                  <option value="bahrain">Bahrain</option>
                                  <option value="bangladesh">Bangladesh</option>
                                  <option value="barbados">Barbados</option>
                                  <option value="belgium">Belgium</option>
                                  <option value="belize">Belize</option>
                                  <option value="benin">Benin</option>
                                  <option value="bhutan">Bhutan</option>
                                  <option value="bolivia">Bolivia</option>
                                  <option value="bosnia_and_herzegovina">
                                    Bosnia and Herzegovina
                                  </option>
                                  <option value="botswana">Botswana</option>
                                  <option value="brazil">Brazil</option>
                                  <option value="brunei">Brunei</option>
                                  <option value="bulgaria">Bulgaria</option>
                                  <option value="burkina_faso">
                                    Burkina Faso
                                  </option>
                                  <option value="burundi">Burundi</option>
                                  <option value="cambodia">Cambodia</option>
                                  <option value="cameroon">Cameroon</option>
                                  <option value="canada">Canada</option>
                                  <option value="cape_verde">Cape Verde</option>
                                  <option value="chile">Chile</option>
                                  <option value="china">China</option>
                                  <option value="cyprus">Cyprus</option>
                                  <option value="colombia">Colombia</option>
                                  <option value="comoros">Comoros</option>
                                  <option value="democratic_republic_of_the_congo">
                                    Democratic Republic of the Congo
                                  </option>
                                  <option value="republic_of_the_congo">
                                    Republic of the Congo
                                  </option>
                                  <option value="north_korea">
                                    North Korea
                                  </option>
                                  <option value="south_korea">
                                    South Korea
                                  </option>
                                  <option value="costa_rica">Costa Rica</option>
                                  <option value="ivory_coast">
                                    Ivory Coast
                                  </option>
                                  <option value="croatia">Croatia</option>
                                  <option value="cuba">Cuba</option>
                                  <option value="denmark">Denmark</option>
                                  <option value="djibouti">Djibouti</option>
                                  <option value="dominica">Dominica</option>
                                  <option value="egypt">Egypt</option>
                                  <option value="el_salvador">
                                    El Salvador
                                  </option>
                                  <option value="united_arab_emirates">
                                    United Arab Emirates
                                  </option>
                                  <option value="ecuador">Ecuador</option>
                                  <option value="eritrea">Eritrea</option>
                                  <option value="spain">Spain</option>
                                  <option value="estonia">Estonia</option>
                                  <option value="united_states">
                                    United States
                                  </option>
                                  <option value="ethiopia">Ethiopia</option>
                                  <option value="fiji">Fiji</option>
                                  <option value="finland">Finland</option>
                                  <option value="france">France</option>
                                  <option value="gabon">Gabon</option>
                                  <option value="gambia">Gambia</option>
                                  <option value="georgia">Georgia</option>
                                  <option value="ghana">Ghana</option>
                                  <option value="greece">Greece</option>
                                  <option value="grenada">Grenada</option>
                                  <option value="guatemala">Guatemala</option>
                                  <option value="guinea">Guinea</option>
                                  <option value="guinea_bissau">
                                    Guinea-Bissau
                                  </option>
                                  <option value="equatorial_guinea">
                                    Equatorial Guinea
                                  </option>
                                  <option value="guyana">Guyana</option>
                                  <option value="haiti">Haiti</option>
                                  <option value="honduras">Honduras</option>
                                  <option value="hungary">Hungary</option>
                                  <option value="india">India</option>
                                  <option value="indonesia">Indonesia</option>
                                  <option value="iraq">Iraq</option>
                                  <option value="iran">Iran</option>
                                  <option value="ireland">Ireland</option>
                                  <option value="iceland">Iceland</option>
                                  <option value="israel">Israel</option>
                                  <option value="italy">Italy</option>
                                  <option value="jamaica">Jamaica</option>
                                  <option value="japan">Japan</option>
                                  <option value="jordan">Jordan</option>
                                  <option value="kazakhstan">Kazakhstan</option>
                                  <option value="kenya">Kenya</option>
                                  <option value="kyrgyzstan">Kyrgyzstan</option>
                                  <option value="kiribati">Kiribati</option>
                                  <option value="kuwait">Kuwait</option>
                                  <option value="laos">Laos</option>
                                  <option value="lesotho">Lesotho</option>
                                  <option value="latvia">Latvia</option>
                                  <option value="lebanon">Lebanon</option>
                                  <option value="liberia">Liberia</option>
                                  <option value="libya">Libya</option>
                                  <option value="lithuania">Lithuania</option>
                                  <option value="luxembourg">Luxembourg</option>
                                  <option value="madagascar">Madagascar</option>
                                  <option value="malawi">Malawi</option>
                                  <option value="malaysia">Malaysia</option>
                                  <option value="maldives">Maldives</option>
                                  <option value="mali">Mali</option>
                                  <option value="malta">Malta</option>
                                  <option value="mauritania">Mauritania</option>
                                  <option value="mauritius">Mauritius</option>
                                  <option value="mexico">Mexico</option>
                                  <option value="moldova">Moldova</option>
                                  <option value="monaco">Monaco</option>
                                  <option value="mongolia">Mongolia</option>
                                  <option value="montenegro">Montenegro</option>
                                  <option value="morocco">Morocco</option>
                                  <option value="mozambique">Mozambique</option>
                                  <option value="myanmar">Myanmar</option>
                                  <option value="namibia">Namibia</option>
                                  <option value="nepal">Nepal</option>
                                  <option value="netherlands">
                                    Netherlands
                                  </option>
                                  <option value="new_zealand">
                                    New Zealand
                                  </option>
                                  <option value="nicaragua">Nicaragua</option>
                                  <option value="niger">Niger</option>
                                  <option value="nigeria">Nigeria</option>
                                  <option value="north_macedonia">
                                    North Macedonia
                                  </option>
                                  <option value="norway">Norway</option>
                                  <option value="oman">Oman</option>
                                  <option value="pakistan">Pakistan</option>
                                  <option value="palestine">Palestine</option>
                                  <option value="panama">Panama</option>
                                  <option value="papua_new_guinea">
                                    Papua New Guinea
                                  </option>
                                  <option value="paraguay">Paraguay</option>
                                  <option value="peru">Peru</option>
                                  <option value="philippines">
                                    Philippines
                                  </option>
                                  <option value="poland">Poland</option>
                                  <option value="portugal">Portugal</option>
                                  <option value="qatar">Qatar</option>
                                  <option value="romania">Romania</option>
                                  <option value="rwanda">Rwanda</option>
                                  <option value="saudi_arabia">
                                    Saudi Arabia
                                  </option>
                                  <option value="senegal">Senegal</option>
                                  <option value="serbia">Serbia</option>
                                  <option value="seychelles">Seychelles</option>
                                  <option value="sierra_leone">
                                    Sierra Leone
                                  </option>
                                  <option value="singapore">Singapore</option>
                                  <option value="slovakia">Slovakia</option>
                                  <option value="slovenia">Slovenia</option>
                                  <option value="somalia">Somalia</option>
                                  <option value="south_africa">
                                    South Africa
                                  </option>
                                  <option value="south_korea">
                                    South Korea
                                  </option>
                                  <option value="south_sudan">
                                    South Sudan
                                  </option>
                                  <option value="spain">Spain</option>
                                  <option value="sri_lanka">Sri Lanka</option>
                                  <option value="sudan">Sudan</option>
                                  <option value="suriname">Suriname</option>
                                  <option value="sweden">Sweden</option>
                                  <option value="switzerland">
                                    Switzerland
                                  </option>
                                  <option value="syria">Syria</option>
                                  <option value="taiwan">Taiwan</option>
                                  <option value="tajikistan">Tajikistan</option>
                                  <option value="tanzania">Tanzania</option>
                                  <option value="thailand">Thailand</option>
                                  <option value="togo">Togo</option>
                                  <option value="tunisia">Tunisia</option>
                                  <option value="turkey">Turkey</option>
                                  <option value="turkmenistan">
                                    Turkmenistan
                                  </option>
                                  <option value="uganda">Uganda</option>
                                  <option value="ukraine">Ukraine</option>
                                  <option value="united_arab_emirates">
                                    United Arab Emirates
                                  </option>
                                  <option value="united_kingdom">
                                    United Kingdom
                                  </option>
                                  <option value="united_states">
                                    United States
                                  </option>
                                  <option value="uruguay">Uruguay</option>
                                  <option value="uzbekistan">Uzbekistan</option>
                                  <option value="venezuela">Venezuela</option>
                                  <option value="vietnam">Vietnam</option>
                                  <option value="yemen">Yemen</option>
                                  <option value="zambia">Zambia</option>
                                  <option value="zimbabwe">Zimbabwe</option>
                                </Form.Select>
                              </FloatingLabel>
                              <Form.Control.Feedback type="invalid">
                                {errors.country}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="7"
                              controlId="validationCustomUsername"
                              className="mt-3"
                            >
                              <InputGroup hasValidation className="country">
                                <InputGroup.Text id="inputGroupPrepend">
                                  {countryCode}
                                </InputGroup.Text>
                                <FloatingLabel
                                  controlId="floatingPhoneInput"
                                  label="Phone"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="phone"
                                    name="phoneNumber"
                                    value={values.phoneNumber}
                                    onChange={handleChange}
                                    isInvalid={!!errors.phoneNumber}
                                    aria-describedby="inputGroupPrepend"
                                    required
                                  />
                                </FloatingLabel>
                                <Form.Control.Feedback type="invalid">
                                  {errors.phoneNumber}
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Row>
                          <Form.Group className="mb-3">
                            <Form.Check
                              required
                              name="terms"
                              onChange={handleChange}
                              isInvalid={!!errors.terms}
                              feedback={errors.terms}
                              feedbackType="invalid"
                              id="validationFormik0"
                            />
                            <span className="ms-2" onClick={handleModalClick}>
                              {" "}
                              Agree to terms and conditions
                            </span>
                          </Form.Group>
                          <TermsConditions
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                          />
                          <div className="d-grid gap-2">
                            <button
                              type="submit"
                              className="btn btn-primary btn-lg"
                            >
                              Complete profile
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                )}
                {stepperItemComponent === 3 && (
                  <>
                    <div className="reviews login-panel p-5 mb-3">
                      <h3 className="lead">
                        <strong>
                          Option 1: Verify your domain with using email with
                          domain name
                        </strong>
                      </h3>
                      <p>
                        Enter the email address associated with{" "}
                        <span className="text-success">
                          {user.website.replace("www.", "")}
                        </span>
                        . We’ll only use it to verify your domain ownership.
                        You’ll still need{" "}
                        <span className="text-success">{user.email}</span> to
                        log into your VeritaTrust account.
                      </p>
                      <div class="input-group my-3">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Recipient's username"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          value={emailDomainName}
                          onChange={handleEmailDomaineName}
                        />
                        <span class="input-group-text" id="basic-addon2">
                          @{user.website.replace("www.", "")}
                        </span>
                        <button
                          class="btn btn-outline-secondary"
                          type="button"
                          onClick={handleSendEmailDomaineName}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                    <div className="reviews login-panel p-5">
                      <h3 className="lead">
                        <strong>
                          {" "}
                          Option 2: Verify your domain with DNS Method
                        </strong>
                      </h3>
                      <p>
                        To finalize your register, you need to verify your
                        domain name.
                      </p>

                      <ol>
                        <li>Download the file vt{merchantData?.id}.html</li>
                        <li>Upload this file in your root website</li>
                        <li>
                          Click to "Verify your domain" to finalize the process
                        </li>
                      </ol>
                      <p className="small mb-3">
                        *please make sure you uploaded the file before
                        verification
                      </p>

                      <div className="d-flex justify-content-between">
                        <a
                          className="btn btn-primary"
                          href={`https://api.b2b.veritatrust.com/v1/merchant-domain/generate-verification-file/${merchantData?.id}`}
                          download={true}
                        >
                          Download file
                        </a>
                        <button
                          class="btn btn-warning"
                          type="button"
                          disabled={loading}
                          onClick={handleVerify}
                        >
                          {loading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm me-2"
                                aria-hidden="true"
                              ></span>
                              <span role="status">Loading...</span>
                            </>
                          ) : (
                            "Verify domain"
                          )}
                        </button>
                      </div>
                      {displayError && (
                        <div className="callout callout-error mt-3">
                          Your verification is not valid.
                        </div>
                      )}
                    </div>
                    {displayMessage && (
                      <div className="callout callout-info">
                        Check your mailbox to verify your domain. We sent you an
                        email in{" "}
                        <span className="text-success">
                          (
                          {emailDomainName +
                            "@" +
                            user.website.replace("www.", "")}
                          )
                        </span>
                      </div>
                    )}

                    {displayMSG >= 3 && (
                      <div className="callout callout-info mt-3">
                        Try contacting support{" "}
                        <span className="text-success">
                          contact@veritatrust.com
                        </span>{" "}
                        if you can't verify the domain name
                      </div>
                    )}
                  </>
                )}

                {stepperItemComponent === 1 && (
                  <div className="reviews login-panel p-5">
                    <p>
                      We sent you an email confirmation
                      <span className="text-success">{user.email}</span>. Please
                      check your mailbox.
                    </p>
                  </div>
                )}

                {stepperItemComponent === 4 && (
                  <div className="reviews login-panel p-5">
                    <p>
                      Your account is pending. It will be approved by
                      VeritaTrust Team!
                    </p>
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      }
    </>
  );
}

export default CompleteProfilePage;
